import { createStore } from 'vuex';
import axios from 'axios';
import _ from 'lodash';
import router from '../router';

const cartMetaDefault = {
	count: 0,
	deliveryDate: '',
	warning: {
		min2: {
			heading: '',
			content: '',
		},
		min5: {
			heading: '',
			content: '',
		},
	},
	timer: {
		localTime: 0,
		secondsLeft: 0,
	},
};

const store = {
	state() {
		return {
			quote: JSON.parse(localStorage.getItem('quote')) || {},
			cart: JSON.parse(localStorage.getItem('cart')) || {},
			cartMeta: JSON.parse(localStorage.getItem('cartMeta')) || cartMetaDefault,
			wl: {},
			canUpdateBreadcrumb: false,
			metaDetails: JSON.parse(localStorage.getItem('metaDetails')) || {},
			colorBubbles: JSON.parse(localStorage.getItem('colorBubbles')) || {},
			wishList: localStorage.getItem('wishlistCount') || {},
			wishListItems: localStorage.getItem('wishlistItems') || [],
			loggedinUser: JSON.parse(localStorage.getItem('loggedinUser')) || {},
			isLoggingOut: false,
			activeTab: '',
			shopMegaMenu: [],
			dynamicBreadcrumb: {},
			breadcrumbDetails: '',
			deleteProdModal: {
				show: false,
				product: {},
				prodId: 0,
			},
			deletePPModal: {
				show: false,
				ppId: 0,
			},
		};
	},
	getters: {
		getBreadcrumbDetails(state) {
			return state.breadcrumbDetails;
		},
		getCanUpdateBreadcrumb(state) {
			return state.canUpdateBreadcrumb
		},
		getDynamicBreadcrumb(state) {
			return state.dynamicBreadcrumb;
		},
		quoteDetails(state) {
			return state.quote;
		},
		quoteItemCount(state) {
			return _.size(state.quote);
		},
		isSalesRep(state, getters) {
			if (getters.isLoggedIn) {
				return getters.loggedinUserDetails.is_sales_rep;
			}
			return 0;
		},
		isESales(state, getters) {
			if (getters.isLoggedIn) {
				return getters.loggedinUserDetails.is_e_sales;
			}
			return 0;
		},
		isLoggedIn(state) {
			return !!Object.keys(state.loggedinUser).length;
		},
		isLoggingOut(state) {
			return state.isLoggingOut;
		},
		loggedinUserDetails(state) {
			return state.loggedinUser;
		},
		getShopMegaMenu(state) {
			return state.shopMegaMenu;
		},
		getWishListItems(state) {
			return state.wishListItems;
		},
		wishListCount(state) {
			return state.wishList;
		},
		getMetaDetails(state) {
			let brandMetaTitle = JSON.parse(localStorage.getItem('site_settings'));
			if (brandMetaTitle) {
				state.metaDetails.meta_title = state.metaDetails.meta_title_backup + ' ' + brandMetaTitle.meta_title_brand;
			}
			return state.metaDetails;
		},
		getCurrentCartTypes(state) {
			let carts = state.cart;
			let allCartsCode = '';
			let allPrograms = Array();
			Object.values(carts).forEach((val) => {
				if ((allPrograms.indexOf(val.header.program_type)) < 0) {
					allPrograms.push(val.header.program_type);
				}
			});
			if (allPrograms.length > 0) {
				allCartsCode = allPrograms.join();
			}
			return allCartsCode;
		},
		getCurrentShoppingLists(state) {
			let carts = state.cart;
			let allShoppingListIds = '';
			let allShoppingLists = Array();
			Object.values(carts).forEach((val) => {
				if (val.header.shopping_list_id != null && val.header.shopping_list_id != undefined && (allShoppingLists.indexOf(val.header.shopping_list_id) < 0)) {
					allShoppingLists.push(val.header.shopping_list_id);
				}
			});
			if (allShoppingLists.length > 0) {
				allShoppingListIds = allShoppingLists.join();
			}
			return allShoppingListIds;
		},
		getCurrentHolHolidays(state) {
			let carts = state.cart;
			let allHolHolidayIds = '';
			let allHolHolidays = Array();
			Object.values(carts).forEach((val) => {
				if (val.header.hol_holiday_id != null && val.header.hol_holiday_id != undefined && (allHolHolidays.indexOf(val.header.hol_holiday_id) < 0)) {
					allHolHolidays.push(val.header.hol_holiday_id);
				}
			});
			if (allHolHolidays.length > 0) {
				allHolHolidayIds = allHolHolidays.join();
			}
			return allHolHolidayIds;
		},
		getShopDeliveryDate(state) {
			return state.cartMeta.shpDeliveryDate;
		},
		getDdbDeliveryDate(state) {
			return state.cartMeta.ddbDeliveryDate;
		},
		getHolDeliveryDate(state) {
			return state.cartMeta.holDeliveryDate;
		},
		getHgsDeliveryDate(state) {
			return state.cartMeta.hgsDeliveryDate;
		},
		getMmtDeliveryDate(state) {
			return state.cartMeta.mmtDeliveryDate;
		},
		getSpecialsDeliveryDate(state) {
			return state.cartMeta.spsDeliveryDate;
		},
		getSblDeliveryDate(state) {
			return state.cartMeta.sblDeliveryDate;
		},
		getColorBubbles(state) {
			return state.colorBubbles;
		},
		canEnrollLoyalty(state, getters) {
			return getters.isLoggedIn && getters.loggedinUserDetails.loyalty_status && getters.loggedinUserDetails.can_enroll_loyalty &&  
			!getters.loggedinUserDetails.is_enrolled_loyalty;
		},
		isEnrolledLoyalty(state, getters) {
			return getters.isLoggedIn && getters.loggedinUserDetails.loyalty_status && getters.loggedinUserDetails.can_enroll_loyalty && 
			getters.loggedinUserDetails.is_enrolled_loyalty;
		},
		canViewLoyalty(state, getters) {
			return getters.isLoggedIn && getters.loggedinUserDetails.can_enroll_loyalty && getters.loggedinUserDetails.is_enrolled_loyalty;
		},
		canChangeLoyaltyStatus(state, getters) {
			return getters.isLoggedIn  && getters.loggedinUserDetails.loyalty_status && getters.loggedinUserDetails.can_enroll_loyalty && 
			!getters.isSalesRep && !getters.isESales;
		},
	},
	mutations: {
		loggingOut(state) {
			state.isLoggingOut = true;
		},
		loggedOut(state) {
			state.isLoggingOut = false;
		},
		setCanUpdateBreadcrumb(state, status) {
			state.canUpdateBreadcrumb = status
		},
		setBreadcrumbDetails(state, breadcrumbDetails) {
			state.breadcrumbDetails = breadcrumbDetails;
		},
		updateDynamicBreadcrumb(state, data) {
			state.dynamicBreadcrumb = data;
		},
		updateQuoteQuant(state, dataObj) {
			if (dataObj.prodId in state.quote) {
				state.quote[dataObj.prodId].quant = dataObj.quant;
			}
			localStorage.setItem('quote', JSON.stringify(state.quote));
		},
		removeFromQuote(state, prodId) {
			if (prodId in state.quote) {
				delete state.quote[prodId];
				localStorage.setItem('quote', JSON.stringify(state.quote));
			}
		},
		storeQuote(state, dataObj) {
			if (dataObj.prodId in state.quote) {
				state.quote[dataObj.prodId].quant += dataObj.quant;
			} else {
				state.quote[dataObj.prodId] = {
					quant: dataObj.quant,
					unitId: dataObj.unitId,
					prodUnit: dataObj.unit,
					prodImage: dataObj.prodImage,
					prodName: dataObj.prodName,
					prodSeo: dataObj.prodSeo,
					prodTypeId: dataObj.prodTypeId,
					min_increment: dataObj.min_increment
				};
			}
			localStorage.setItem('quote', JSON.stringify(state.quote));
		},
		clearQuote(state) {
			state.quote = {};
			localStorage.removeItem('quote');
		},
		setCartMeta(state, data) {
			const obj = {
				count: data.count,
				extendCartTimer: data.extend_cart_timer,
				shpDeliveryDate: data.shpDeliveryDate,
				ddbDeliveryDate: data.ddbDeliveryDate,
				holDeliveryDate: data.holDeliveryDate,
				hgsDeliveryDate: data.hgsDeliveryDate,
				mmtDeliveryDate: data.mmtDeliveryDate,
				spsDeliveryDate: data.spsDeliveryDate,
				sblDeliveryDate: data.sblDeliveryDate,
				timer: {
					localTime: new Date(),
					secondsLeft: data.secondsLeft,
				},
				warning: data.warning,
			};
			state.cartMeta = obj;
			localStorage.setItem('cartMeta', JSON.stringify(obj));
		},
		clearCartMeta(state) {
			state.cartMeta = cartMetaDefault;
			localStorage.removeItem('cartMeta');
		},
		loadCart(state, data) {
			state.cart = data;
			localStorage.setItem('cart', JSON.stringify(data));
		},
		loadMetaDetails(state, data) {
			data.meta_title_backup ??= data.meta_title;
			state.metaDetails = data;
			localStorage.setItem('metaDetails', JSON.stringify(data));
		},
		loadColorBubbles(state, data) {
			state.colorBubbles = data;
			localStorage.setItem('colorBubbles', JSON.stringify(data));
		},
		clearCart(state) {
			state.cart = {};
			localStorage.removeItem('cart');
		},
		/* 
		addToCart(state, prod) {
			if (prod.id in state.cart) {
				state.cart[prod.id].quantity += prod.quantity;
			} else {
				state.cart[prod.id] = {
					quantity: prod.quantity,
				};
			}
		}, */
		addToWL(state, prodId) {
			state.wl[`${prodId}`] = prodId;
		},
		addToWishlistStore(state, productCount) {
			state.wishList = productCount;
			localStorage.setItem('wishlistCount', state.wishList);
		},
		addToWishlistDetailsStore(state, products) {
			state.wishListItems = products;
			localStorage.setItem('wishlistItems', state.wishListItems);
		},
		storeUserDetails(state, user) {
			state.loggedinUser = user;
			localStorage.setItem('loggedinUser', JSON.stringify(user));
		},
		clearUserDetails(state) {
			state.loggedinUser = {};
			localStorage.removeItem('loggedinUser');
			localStorage.removeItem('token');
		},
		changeTab(state, tabId) {
			state.activeTab = tabId;
		},
		storeMegaMenu(state, shopMegaMenu) {
			state.shopMegaMenu = shopMegaMenu;
		},
		showDeleteProdWarn(state, product) {
			state.deleteProdModal.show = true;
			state.deleteProdModal.prodId = product.cartItemId;
			state.deleteProdModal.product = product;
		},
		showDeletePPWarn(state, ppId) {
			state.deletePPModal.show = true;
			state.deletePPModal.ppId = ppId;
		},
		hideDeleteProdWarn(state) {
			state.deleteProdModal.show = false;
		},
		hideDeletePPWarn(state) {
			state.deletePPModal.show = false;
		},
	},
	actions: {
		doLogout({ getters, commit, dispatch }) {
			if (getters.isLoggedIn && !getters.isLoggingOut) {
				commit('loggingOut');
					return dispatch('logout').then(() => {
					commit('clearCartMeta');
					commit('clearCart');
					commit('clearQuote');
					commit('loggedOut');
					let newSortBy = localStorage.getItem('sortBy').replace('Price','Name');
					localStorage.setItem('sortBy', newSortBy);
					this.emitter.emit('updateMegamenu');
					router.push({ name: 'Login' });
				});
			}
		},
		fetchColorBubbles({ commit }) {
			return axios
				.get(process.env.VUE_APP_API_BASE + 'auth/getColorBubbles', {
					headers: {
						'Content-type': 'application/json',
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					},
				})
				.then(({ data }) => {
					commit('loadColorBubbles', data.colorBubbles);
				});
		},

		clearCartApi({ dispatch }) {
			return axios
				.get(process.env.VUE_APP_API_BASE + 'auth/clearCurrentCarts', {
					headers: {
						'Content-type': 'application/json',
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					},
				})
				.then(() => {
					return dispatch('fetchCartAndMeta');
				});
		},
		clearCartByProgram({ dispatch }) {
			let program = localStorage.getItem('cartProgram');
			let shoppingListId = localStorage.getItem('shoppingListId');
			let holHolidayId = localStorage.getItem('holHolidayId');
			return axios
				.post(process.env.VUE_APP_API_BASE + 'auth/clearCartByProgram', {
					program: program,
					shopping_list_id: shoppingListId,
					hol_holiday_id: holHolidayId
				},
				{
					headers: {
						'Content-type': 'application/json',
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					},
				})
				.then(() => {
					localStorage.removeItem('cartProgram');
					localStorage.removeItem('shoppingListId');
					return dispatch('fetchCartAndMeta');
				});
		},
		clearCartApiByTimer({ dispatch }) {
			return axios
				.get(process.env.VUE_APP_API_BASE + 'auth/clearCurrentCartsByTimer', {
					headers: {
						'Content-type': 'application/json',
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					},
				})
				.then(() => {
					this.emitter.emit('cartExpiredReload');
					//window.location.reload()
					return dispatch('fetchCartAndMeta');
				});
		},
		fetchCartAndMeta({ dispatch }) {
			return dispatch('fetchCart').then(() => {
				return dispatch('fetchCartMeta');
			});
		},
		fetchCart({ getters, commit }) {
			return axios
				.post(process.env.VUE_APP_API_BASE + 'auth/getCarts', 
				{
					is_sales_rep: getters.isSalesRep,
					is_e_sales: getters.isESales,
				},
				{
					headers: {
						'Content-type': 'application/json',
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					},
				})
				.then(({ data }) => {
					let cartsCount = 0;
					if (data.carts.length == undefined) cartsCount = Object.getOwnPropertyNames(data.carts).length;
					else cartsCount = data.carts.length;

					if (cartsCount < 1) {
						let site_settings = JSON.parse(localStorage.getItem('site_settings'));
						let cartsType = [
							parseInt(site_settings.miami_direct_boxlots_id),
							parseInt(site_settings.live_local_id),
							parseInt(site_settings.dutch_direct_boxlots_id),
							parseInt(site_settings.farm_direct_boxlots_id),
							parseInt(site_settings.flower_futures_id),
							parseInt(site_settings.hardgoods_id),
							parseInt(site_settings.mass_markets_id),
							parseInt(site_settings.weekly_specials_id),
						];
						let cartsCommentExists = '';
						cartsType.forEach((cartId) => {
							cartsCommentExists = localStorage.getItem('cartCommentsDetails_' + cartId);
							if (cartsCommentExists) localStorage.removeItem('cartCommentsDetails_' + cartId);

							cartsCommentExists = localStorage.getItem('cartPoDetails_' + cartId);
							if (cartsCommentExists) localStorage.removeItem('cartPoDetails_' + cartId);
						});
					} else {
						let exceptCart = [];
						for (const progId in data.carts) {
							exceptCart.push(parseInt(progId));
						}

						let site_settings = JSON.parse(localStorage.getItem('site_settings'));
						let cartsType = [
							parseInt(site_settings.miami_direct_boxlots_id),
							parseInt(site_settings.live_local_id),
							parseInt(site_settings.dutch_direct_boxlots_id),
							parseInt(site_settings.farm_direct_boxlots_id),
							parseInt(site_settings.flower_futures_id),
							parseInt(site_settings.hardgoods_id),
							parseInt(site_settings.mass_markets_id),
							parseInt(site_settings.weekly_specials_id),
						];
						let cartsCommentExists = '';
						let difference = [];
						if (exceptCart.length > 0) {
							difference = cartsType.filter((x) => !exceptCart.includes(x)).concat(exceptCart.filter((x) => !cartsType.includes(x)));
						} else {
							difference = cartsType;
						}
						difference.forEach((cartId) => {
							cartsCommentExists = localStorage.getItem('cartCommentsDetails_' + cartId);
							if (cartsCommentExists) localStorage.removeItem('cartCommentsDetails_' + cartId);

							cartsCommentExists = localStorage.getItem('cartPoDetails_' + cartId);
							if (cartsCommentExists) localStorage.removeItem('cartPoDetails_' + cartId);
						});
					}
					commit('loadCart', data.carts);
				});
		},
		fetchCartMeta({ commit }) {
			return axios
				.get(process.env.VUE_APP_API_BASE + 'auth/getCartMeta', {
					headers: {
						'Content-type': 'application/json',
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					},
				})
				.then(({ data }) => {
					if (!('shpDeliveryDate' in data.meta)) {
						data.meta.shpDeliveryDate = null;
					}
					if (!('ddbDeliveryDate' in data.meta)) {
						data.meta.ddbDeliveryDate = null;
					}
					if (!('holDeliveryDate' in data.meta)) {
						data.meta.holDeliveryDate = null;
					}
					if (!('hgsDeliveryDate' in data.meta)) {
						data.meta.hgsDeliveryDate = null;
					}
					if (!('mmtDeliveryDate' in data.meta)) {
						data.meta.mmtDeliveryDate = null;
					}
					if (!('spsDeliveryDate' in data.meta)) {
						data.meta.spsDeliveryDate = null;
					}
					if (!('sblDeliveryDate' in data.meta)) {
						data.meta.sblDeliveryDate = null;
					}

					commit('setCartMeta', data.meta);
				});
		},
		fetchWishList({ commit }) {
			return axios
				.get(process.env.VUE_APP_API_BASE + 'auth/getWishlistProducts', {
					headers: {
						'Content-type': 'application/json',
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					},
				})
				.then(({ data }) => {
					commit('addToWishlistStore', data.count);
					commit('addToWishlistDetailsStore', data.products);
				});
		},
		fetchQuoteList({ commit }) {
			return axios
				.post(
					`${process.env.VUE_APP_API_BASE}auth/getPendingQuotes`,
					{},
					{
						headers: {
							'Content-type': 'application/json',
							Authorization: 'Bearer ' + localStorage.getItem('token'),
						},
					}
				)
				.then(({ data }) => {
					// localStorage.removeItem('quote')
					data.products.forEach((prod) => {
						commit('storeQuote', {
							quant: prod.quantity,
							prodId: prod.product_id,
							unitId: prod.unit_id,
							prodImage: prod.image,
							prodName: prod.name,
							unit: prod.unit,
							prodSeo: prod.seo_url,
							prodTypeId: prod.product_type_id,
						});
					});
				});
		},
		fetchMetaDetails({ commit }) {
			let slug = localStorage.getItem('currentPage');
			return axios
				.post(process.env.VUE_APP_API_BASE + 'auth/getPageSettings', {
					slug: slug,
					headers: {
						'Content-type': 'application/json',
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					},
				})
				.then(({ data }) => {
					commit('loadMetaDetails', data.page);
					localStorage.removeItem('currentPage');
					localStorage.removeItem('metaDetails');
				});
		},
		deleteProduct({ state, commit, dispatch }) {
			this.emitter.emit('showLoader');
			commit('hideDeleteProdWarn');
			axios
				.post(
					`${process.env.VUE_APP_API_BASE}auth/removeItem`,
					{
						cartItemId: state.deleteProdModal.prodId,
					}, // the data to post
					{
						headers: {
							'Content-type': 'application/json',
							Authorization: 'Bearer ' + localStorage.getItem('token'),
						},
					}
				)
				.then(({ data }) => {
					dispatch('fetchCartAndMeta');

					let level = 'error';
					if (data.error == false) {
						level = 'success';

						let product = state.deleteProdModal.product
						const totalPrice = parseFloat(product.total);
						let productObj = {
							event_category: "purchase",
							event_label: 'remove from cart',
							value: parseFloat(totalPrice),
							items: {
								item_id: product.product_id,
								item_name: product.name,
								affiliation: product.farm_name,
								item_list_id: product.programtypeId,
								item_list_name: product.programtypeName,
								item_category: product.category_name,
								item_category2: product.variety_name,
								item_variant: product.color_name,
								item_category3: product.grade_name,
								quantity: parseInt(product.quantity),
								price: parseFloat(product.price_per_unit),
								currency: "USD",
							},
						};

						this.emitter.emit('deletedProductGA', {
							product: productObj
						});
					}

					this.emitter.emit('deletedProduct', {
						status: data.error,
						msg: data.message,
						level: level,
					});
				});
		},
		deletePP({ state, commit }) {
			this.emitter.emit('showLoader');
			commit('hideDeletePPWarn');
			axios
				.post(
					`${process.env.VUE_APP_API_BASE}auth/getSavedProductPlanner`,
					{
						product_planner_id: state.deletePPModal.ppId,
					}, // the data to post
					{
						headers: {
							'Content-type': 'application/json',
							Authorization: 'Bearer ' + localStorage.getItem('token'),
						},
					}
				)
				.then(({ data }) => {
					this.emitter.emit('deleted_pp_details', {
						details: data
					});
				});
			
			
			axios
				.post(
					`${process.env.VUE_APP_API_BASE}auth/deleteProductPlanner`,
					{
						product_planner_id: state.deletePPModal.ppId,
					}, // the data to post
					{
						headers: {
							'Content-type': 'application/json',
							Authorization: 'Bearer ' + localStorage.getItem('token'),
						},
					}
				)
				.then(({ data }) => {
					let level = 'error';
					if (data.error == false) {
						level = 'success';
					}

					this.emitter.emit('deletedPP', {
						status: data.error,
						msg: data.message,
						level: level,
						deleted_pp_id: state.deletePPModal.ppId,
					});
				});
		},
		addToQuoteBulk({ state, getters, commit }) {
			if (getters.quoteItemCount) {
				this.emitter.emit('showLoader');

				const payload = [];
				_.each(state.quote, (details, prodId) => {
					payload.push({ product_id: prodId, unit_id: details.unitId, quantity: details.quant });
				});

				return axios
					.post(
						`${process.env.VUE_APP_API_BASE}auth/bulkAddToQuote`,
						{
							products: payload,
						}, // the data to post
						{
							headers: {
								'Content-type': 'application/json',
								Authorization: 'Bearer ' + localStorage.getItem('token'),
							},
						}
					)
					.then(() => {
						commit('clearQuote');
						this.emitter.emit('hideLoader');
					});
			} else {
				return Promise.resolve();
			}
		},
		logout({ commit }) {
			if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
				return axios
					.post(
						process.env.VUE_APP_API_BASE + 'auth/customerLogout',
						{},
						{
							headers: {
								'Content-type': 'application/json',
								Authorization: 'Bearer ' + localStorage.getItem('token'),
							},
						}
					)
					.then(() => {
						commit('clearUserDetails');
						location.reload();
					})
					.catch ((error) => {
						console.log(error.response);
						commit('clearUserDetails');
					});
			} else {
				commit('clearUserDetails');
				location.reload();
			}
		},
	},
};

export default createStore(store); // Create a new store instance.
